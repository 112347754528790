.Page {
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-wrap: normal;
  width: 100vw;
  min-height: 100vh;
  padding: calc(6vw + 30px) 0;

  /* times font */
  font-family: 'Times New Roman', Times, serif;
}

.sentence {
  width: 90%;
  max-width: 800px;
  user-select: none;
}

/* black textbackground of a single word */
.secret {
  color: black;
  background-color: black;
  word-wrap: none;
}

.pageInfo {
  margin-top: 300px;
  width: 90%;
  max-width: 800px;
}


/* Loading css */
.ldsRoller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsRoller div {
  animation: ldsRoller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.ldsRoller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: rgb(4, 59, 87);
  margin: -4px 0 0 -4px;
}
.ldsRoller div:nth-child(1) {
  animation-delay: -0.036s;
}
.ldsRoller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.ldsRoller div:nth-child(2) {
  animation-delay: -0.072s;
}
.ldsRoller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.ldsRoller div:nth-child(3) {
  animation-delay: -0.108s;
}
.ldsRoller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.ldsRoller div:nth-child(4) {
  animation-delay: -0.144s;
}
.ldsRoller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.ldsRoller div:nth-child(5) {
  animation-delay: -0.18s;
}
.ldsRoller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.ldsRoller div:nth-child(6) {
  animation-delay: -0.216s;
}
.ldsRoller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.ldsRoller div:nth-child(7) {
  animation-delay: -0.252s;
}
.ldsRoller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.ldsRoller div:nth-child(8) {
  animation-delay: -0.288s;
}
.ldsRoller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes ldsRoller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
